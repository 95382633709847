<script setup>

import { onMounted, reactive, ref } from "vue"
import { useRoute, useRouter } from 'vue-router'
import { useCourseStore } from "@/store/courses/course"
import { useToast } from "primevue/usetoast"

import EditorCustom from '../../../modules/ckeditor/EditorCustom.vue'

const route = useRoute()
const router = useRouter()
const toast = useToast()

const courseStore = useCourseStore()
const course = reactive({
  editor_description: null,
  editor_description_detail: null,
})

const creating = ref(false)

const optionBoolean = [
  { label: "Yes", value: true },
  { label: "No", value: false }
]
const loading = ref(false)
const loadingEditor = ref(false)
const uploadDisabled = ref(false)

let oldPicture = {
  picture: null,
  picture_detail: null
}

const uploader = (name, event) => {
  uploadDisabled.value = true
  const reader = new FileReader()
  reader.readAsDataURL(event.files[event.files.length - 1])
  reader.onload = function () {

    course[name] = reader.result
    uploadDisabled.value = false
  }
  reader.onerror = function () {
    uploadDisabled.value = false
  }
}

const uploadRemove = (name) => {
  if (oldPicture[name] !== null) course[name] = oldPicture[name]
  else delete course[name]
}

const save = () => {
  const action = creating.value
    ? courseStore.store(course)
    : courseStore.update(route.params.id, course)
  loading.value = true
  action.then(({ data }) => {
    if (data.error) {
      toast.add({severity:'warn', summary: 'Warning', detail: data.error.message, life: 3000})
      loading.value = false
      return
    }

    toast.add({severity:'success', summary: 'Success', detail: 'Success', life: 3000})

    oldPicture['picture'] = data.course.picture_url
    oldPicture['picture_detail'] = data.course.picture_url_detail

    if (creating.value) {
      setTimeout(() => {
        course['id'] = data.course.id
        course['picture'] = data.course.picture_url
        course['active'] = false
        course['editor_description'] = ''
        course['editor_description_detail'] = ''
        
        router.replace({ name: 'courses.edit', params: { id: data.course.id } })
        creating.value = false
        loading.value = false
      }, 1000)

      return
    }

    loading.value = false
  }).catch((e) => {
    toast.add({severity:'error', summary: 'Error (Catch)', detail: e.message, life: 3000})
  })
}

onMounted(() => {
  creating.value = (typeof route.params.id === 'undefined' && typeof course.id === 'undefined')

  loading.value = true
  if (typeof route.params.id !== 'undefined') {
    courseStore.show(route.params.id)
      .then((res) => {
        const _course = res.data.course

        course['id'] = _course.id
        course['picture_url'] = _course.picture_url
        course['picture_url_detail'] = _course.picture_url_detail

        course['title'] = _course.title
        course['title_detail'] = _course.title_detail

        course['editor_description'] = _course.editor_description || ''
        course['editor_description_detail'] = _course.editor_description_detail || ''

        course['active'] = _course.active

        course['picture'] = course['picture_url']
        oldPicture['picture'] = course['picture_url']

        course['picture_detail'] = course['picture_url_detail']
        oldPicture['picture_detail'] = course['picture_url_detail']

        loading.value = false
      })
      .catch(() => loading.value = false)
  } else {
    loading.value = false
  }
})

</script>
<template>
  <div class="block-section">
    <div class="block-header">
        <span v-if="course.id" class="block-title">
            <div>Edit #{{ course.id }}</div>
        </span>

        <div class="flex align-items-center justify-content-center">
          <Button label="Back" class="p-button-text p-button-plain" @click="$router.go(-1)" />
        </div>

        <br/>
    </div>

    <div class="block-content">
      <div class="grid">
        <div class="col-12">
          <div class="card">
            <h5>Main</h5>

            <div class="px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
              <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                <div>
                  <Image v-if="course.picture" :src="course.picture" preview alt="Image Text" class="mb-3" style="width: 100%"/>
                  <FileUpload
                    v-if="! creating"
                    accept="image/*"
                    :customUpload="true"
                    :auto="true"
                    :showUploadButton="false"
                    :disabled="uploadDisabled"
                    @uploader="uploader('picture', $event)"
                    @clear="uploadRemove('picture')"
                  />

                  <label class="block text-900 font-medium mb-2" for="title">Title</label>
                  <InputText v-model="course.title" id="title" type="text" class="w-full mb-3" />
                </div>
              </div>
            </div>

            <br/>

            <div v-if="! creating" class="flex justify-content-center">
              <div class="col-12 md:col-10 shadow-2">
                <editor-custom
                  v-if="course.editor_description !== null"
                  v-model="course.editor_description"

                  :disabled="loadingEditor"
                  :path="`courses/${course.id}`"
                />
              </div>
            </div>

            <br/><br/>
          </div>
        </div>
      </div>
    </div>

    <div v-if="! creating" class="block-content">
      <div class="grid">
        <div class="col-12">
          <div class="card">
            <h5>Detail</h5>

            <div class="px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
              <div class="surface-card p-4 shadow-2 border-round w-full lg:w-6">
                <div>
                  <Image v-if="course.picture_detail" :src="course.picture_detail" preview alt="Image Text" class="mb-3" style="width: 100%"/>
                  <FileUpload
                    accept="image/*"
                    :customUpload="true"
                    :auto="true"
                    :showUploadButton="false"
                    :disabled="uploadDisabled"
                    @uploader="uploader('picture_detail', $event)"
                    @clear="uploadRemove('picture_detail')"
                  />

                  <label class="block text-900 font-medium mb-2" for="title_detail">Title Detail</label>
                  <InputText v-model="course.title_detail" id="title_detail" type="text" class="w-full mb-3" />
                </div>
              </div>
            </div>

            <br/>

            <div class="flex justify-content-center">
              <div class="col-12 md:col-10 shadow-2">
                <editor-custom
                  v-if="course.editor_description_detail !== null"
                  v-model="course.editor_description_detail"

                  :disabled="loadingEditor"
                  :path="`courses/${course.id}`"
                />
              </div>
            </div>

            <br/><br/>
          </div>
        </div>
      </div>
    </div>

    <div class="grid">
      <div class="col-12">
        <div class="card">
          <div class="px-4 py-3 md:px-6 lg:px-8 flex align-items-center justify-content-center">
            <div class="p-4 w-full lg:w-6">
              <div v-if="! creating">
                <label class="block text-900 font-medium mb-2" for="active">Active</label>
                <SelectButton v-model="course.active" id="active" class="w-full mb-3" :options="optionBoolean" optionLabel="label" optionValue="value" />
              </div>

              <br/>

              <Button label="Save" class="w-full" :loading="loading" @click="save()" />
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<style>
.p-image img {
  width: 100%;
  border: 1px solid #dee2e6 !important;
}
.p-fileupload-buttonbar {
  border-bottom: 1px solid #dee2e6 !important;
  margin-bottom: 1rem;
}
.p-fileupload-content {
  display: none;
}
</style>
